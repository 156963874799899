import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyABb24xxT9vqXimJwao2drzSAA5B60_THE",
  authDomain: "targetupe-new.firebaseapp.com",
  projectId: "targetupe-new",
  storageBucket: "targetupe-new.appspot.com",
  messagingSenderId: "741798869834",
  appId: "1:741798869834:web:7c11aa19de8a62d6ed9fb3",
  measurementId: "G-W8VE7PH24T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);


