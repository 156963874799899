import { useContext, createContext } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
const DataContext = createContext({});

export const useData = () => useContext(DataContext);
const DataProvider = ({ children }) => {
  const fetchPost = async (id) => {
    const docRef = doc(db, 'posts', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      return docSnap.data();
    } else {
      console.log('No such document!');
      return null;
    }
  };

  return (
    <DataContext.Provider value={{ fetchPost }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
