import React, { useState } from "react";
import styles from "./Faq.module.css";
import text from "../constants/text.json";
import color from "../constants/colors.json";

export default function Faq({ faqheading, isRtl, faqContent }) {
  // let FAQ = isRtl ? text.FAQArabic : text.FAQEnglish;
  let questions = [...faqContent.matchAll(/Q:.+/gim)];
  let answers = [...faqContent.matchAll(/A:(.\n?)*(?=\n\n)/gim)];
  console.log(questions);
  console.log(answers);
  const [openItems, setOpenItems] = useState([]);

  const toggleItem = (index) => {
    if (openItems.includes(index)) {
      setOpenItems(openItems.filter((item) => item !== index));
    } else {
      setOpenItems([...openItems, index]);
    }
  };

  return (
    <div className="">
      <section className={styles.faqContainer}>
        <h1 className={styles.faqHeading}>{faqheading}</h1>
        {answers.map((item, index) => (
          <div key={index} className={styles.faqItem} style={{ background: color.accent }} onClick={() => toggleItem(index)}>
            <h4 className={styles.faqQuestion} >
              {questions[index][0].replace(/q:\s+/i,"")}
            </h4>
            {openItems.includes(index) && (
              <div className={styles.faqBody}>
                <p>{answers[index][0].replace(/A:\s+/i,"")}</p>
              </div>
            )}
          </div>
        ))}
      </section>
    </div>
  );
}
