import React from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './Contentright.module.css'
export default function Contentright({title, description,image}) {
  
  const { ref, inView } = useInView({
    triggerOnce: true, 
  });
  return (
    <div className="content-area-l-13-2 position-relative overflow-hidden">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="border-bottom">
              <div className="row justify-content-center">
                <div className="col-xl-11">
                  <div className="row align-items-center justify-content-center justify-content-lg-start">
                    <div
                      className="col-lg-6 col-md-8 col-sm-10"
                      data-aos="fade-right"
                      data-aos-duration="800"
                      data-aos-once="true"
                    >
                      <div className={`${styles.imganimation} ${inView ? styles.inView : ''}`}ref={ref} >
                        <img
                          src={image}
                          alt=""
                          width='350px' height='700px'
                        />
                      </div>
                    </div>
                    <div
                      className="offset-lg-1 col-lg-5 col-md-8 col-sm-10 position-relative" >
                      <div className={`${styles.contentrighttext} ${styles.contentWrapper} ${inView ? styles.inView : ''}`}ref={ref} >
                        <h2>{title}</h2>
                        <p>
                          {description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
