import styles from './Contentleft.module.css'
import { useInView } from 'react-intersection-observer';

export default function Contentleft({title, description,image}) {
  const { ref, inView } = useInView({
    triggerOnce: true, 
  });
  return (
    <div className=" position-relative overflow-hidden" id="feature">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="border-bottom">
              <div className="row justify-content-center">
                <div className="col-xl-11">
                  <div className="row align-items-center justify-content-center justify-content-lg-start">
                    <div
                      className="offset-lg-1 col-lg-5 col-md-8 col-sm-10 order-lg-1 order-1"
                    >
                      <div className={`${styles.contentWrapper} ${inView ? styles.inView : ''}`}ref={ref}>
                      <div className={styles.contentlefttext}>
                        <h2>{title}</h2>
                        <p>
                          {description}
                        </p>
                      </div>
                    </div>
                    </div>
                    <div
                      className="offset-lg-1 col-lg-5 col-md-8 col-sm-10 position-relative order-lg-1 order-0"
                    >
                      <div className={`${styles.imganimation} ${inView ? styles.inView : ''}`}ref={ref}>
                        <img
                          src={image}
                          alt=""
                          width='350px' height='700px'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
