import React, { useState, useEffect } from "react";
import styles from "./Navbar.module.css";
import { Link } from "react-router-dom";

export default function Navbar({
  contact,
  download,
  toggleRtl,
  isRtl,
  image,
  about,
  features,
  policy,
  privacyPolicyContent,
}) {
  const [scrolling, setScrolling] = useState(false);
  const [scrolledHalfway, setScrolledHalfway] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleRtl = () => {
    toggleRtl();
  };

  const handleDownload = () => {
    // Detect the user's device
    const isandroid = /Android/i.test(navigator.userAgent);
    const isapple = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (isandroid) {
      // Redirect to the google store URL
      window.location.href = "https://play.google.com/store/apps/details?id=com.targetube.app";
    } else {
      if (isapple) {
        // Redirect to the app store URL
        window.location.href = "https://apps.apple.com/us/app/targetube/id6468591285";
      } else {
        // Redirect to the desktop download page
        window.location.href = "https://play.google.com/store/apps/details?id=com.targetube.app";
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const hide = window.innerHeight * 0.08;
      const half = window.innerHeight * 0.6;
      const halfway = window.innerHeight * 0.5;

      if (scrollY > hide) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      if (scrollY > half) {
        setScrolling(false);
        document.body.classList.add("scrolled-halfway");
      } else {
        document.body.classList.remove("scrolled-halfway");
      }
      if (scrollY > halfway) {
        setScrolledHalfway(true);
      } else {
        setScrolledHalfway(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSidebarToggle = () => {
    // Toggle the sidebar state
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      className={`${styles.navbar} ${scrolledHalfway ? styles.scrolledhalfway : ""} ${
        scrolling ? styles.hidden : ""
      }`}
    >
      <div className={styles.header}>
        <nav>
          <div className={styles.mobile}>
            <div className={styles.logo}>
              <div
                className={styles.hamburger}
                onClick={handleSidebarToggle} // Add click event to open/close sidebar
              >
                ☰
              </div>
              {/* <a href="#">
                
                <img src={image} width="75px" alt="logo" style={{ borderRadius: "10px" }} />
              </a> */}
            </div>
            {isSidebarOpen && (
              <div className={styles.sidebar}>
                {/* Sidebar Content */}

                <a href="#about">{about}</a>
                <a href="#feature">{features}</a>
                <Link to="/privacy-policy" state={privacyPolicyContent}>
                  {policy}
                </Link>
                <a href="mailto:infotargetube@gmail.com">{contact}</a>

                {/* <div className={styles.btns}>
                  <button onClick={handleToggleRtl} className="toggle-button">
                    {!isRtl ? "TargeTube in Arabic" : "TargeTube in English"}
                  </button>
                </div> */}
              </div>
            )}
          </div>
          <div className={styles.desktop}>
            {/* <a href="#">
              {/* Light version logo (logo must be black) */}
              {/* <img src={image} width="75px" alt="logo" style={{ borderRadius: "10px" }} />
            </a> */} 
            <div className={styles.items}>
              <div className="d-flex align-items-center gap-3">
                <ul className={styles.ul}>
                  <li className={styles.navitem} style={{ listStyle: "none" }}>
                    <a
                      href="mailto:infotargetube@gmail.com"
                      className="nav-link-item dark-mode-texts"
                      style={{ textDecoration: "none" }}
                    >
                      {contact}
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="header-btn header-btn-l-13 ms-auto d-xs-inline-flex">
                <button onClick={handleToggleRtl} className="toggle-button">
                  {!isRtl ? "TargeTube in Arabic" : "TargeTube in English"}
                </button>
              </div> */}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
